.aside {

    nav {

        ul {
            list-style: none;

            li {

                &:not(:last-child) {
                    margin-bottom: 2px;
                }

                a {
                    display: flex;
                    text-decoration: none;
                    line-height: 1;
                    padding: 1.2rem 1.5rem;
                    font-weight: 400;
                    transition: 0.2s;
                    border-radius: var(--rad-smaller);
                    color: var(--text-grey);

                    &.activeLink,
                    &:hover {
                        background: var(--grey-bg);
                        color: var(--text);
                    }

                }

            }

        }

    }

}