.section {
    padding-bottom: 4rem;

    .grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1rem;
    }

    .loadMoreWrapper {
        display: flex;
        justify-content: center;
        margin-top: 4rem;
    }

}

@media (max-width: 768px) {

    .section {

        .grid {
            grid-template-columns: 1fr;
        }

    }

}

@media (min-width: 768px) and (max-width: 1200px) {

    .section {

        .grid {
            grid-template-columns: repeat(2, 1fr);
        }

    }

}