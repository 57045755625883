.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
}

@keyframes scaleIn {
    from {
        opacity: 0;
        transform: translateY(-15%);
    }
    to {
        opacity: 1;
        transform: none;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.blackboard {
    background: var(--blackboard);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    animation: fadeIn 0.6s;
}

.wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4rem 0;
    overflow: auto;
    .content {
        background: var(--grey-bg-opacity);
        backdrop-filter: blur(var(--blur));
        position: relative;
        border-radius: var(--rad-large);
        padding: var(--field);
        width: 40rem;
        max-height: 50rem;
        overflow: auto;
        max-width: 100%;
        box-shadow: var(--shadow);
        animation: scaleIn 0.7s;

        .closeBtn {
            --padding: 1.5rem;
            top: var(--padding);
            right: var(--padding);
            position: absolute;
        }

    }

}