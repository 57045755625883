.widget {
    display: flex;
    align-items: center;
    height: var(--field-smaller);
    background: var(--grey-bg-dark);
    border-radius: var(--rad);
    padding: 0 1rem;
    font-weight: 500;

    .icon {
        display: flex;
        font-size: 1.2em;
        margin-right: 0.3em;
        color: var(--text-grey);
    }

    .arrow {
        display: flex;
        font-size: 0.8em;
        margin-left: 0.3em;
        color: var(--text-grey);
    }

}