.markdownBody {
    line-height: 1.6;
    overflow-x: auto;
}

.markdownBody h1,
.markdownBody h2,
.markdownBody h3,
.markdownBody h4,
.markdownBody h5,
.markdownBody h6 {
    margin-top: 1.5em;
    margin-bottom: 0.75em;
}

.markdownBody h2:before {
    background: var(--text-grey);
    content: '\020';
    display: block;
    height: 2px;
    margin: 1rem 0;
    width: 1em;
}

.markdownBody h2 {
    font-size: 2.4em;
}
.markdownBody h3 {
    font-size: 1.75em;
}

.markdownBody h4 {
    font-size: 1.5em;
}

.markdownBody h5 {
    font-size: 1.25em;
}

.markdownBody h6 {
    font-size: 1em;
}

.markdownBody p {
    margin: 1em 0;
    font-size: 1.5rem;
}

.markdownBody a {
    color: var(--theme);
    text-decoration: none;
    border-bottom: 1px solid var(--theme);
}

.markdownBody a:hover {
    color: var(--theme);
    opacity: 70%;
    border-bottom: 2px solid var(--theme);
}

.markdownBody ul,
.markdownBody ol {
    margin: 1em 0;
    padding-left: 2em;
}

.markdownBody ul li,
.markdownBody ol li {
    font-size: 1.2rem;
    list-style-type: disc;
    margin-bottom: 0.5em;
}

.markdownBody blockquote {
    margin: 1em 0;
    padding: 1em;
    background-color: #202023;
    border-radius: 8px;
    border-left: 10px solid #3f3f46;
    color: rgba(255, 255, 255, 0.7);
}

.markdownBody blockquote::before {
    content: icon;
}

.markdownBody blockquote p {
    margin: 0;
}

.markdownBody pre {
    background: #202023;
    color: #f8f8f2;
    padding: 1em;
    border-radius: 5px;
    overflow-x: auto;
    margin: 1.5em 0;
}

.markdownBody code {
    background: #202023;
    padding: 0.2em 0.4em;
    border-radius: 3px;
    font-family: 'Courier New', Courier, monospace;
}

.markdownBody pre code {
    background: none;
    padding: 0;
    color: inherit;
}

.markdownBody img {
    width: 100%;
    max-height: 30rem;
    object-fit: cover;
    border-radius: 8px;
    margin: 1em 0;
}

.markdownBody table {

    width: 100%;
    border-collapse: collapse;
    margin: 1.5em 0;
    border-radius: 8px;
}

.markdownBody table th,
.markdownBody table td {
    border: 1px solid #202023;
    padding: 0.75em;
}

.markdownBody table th {
    background-color: #3f3f46;
    font-weight: bold;
}

.markdownBody hr {
    border: 0;
    border-top: 1px solid #202023;
    margin: 2em 0;
}