.blogFeed {
    width: 100%;
    margin-bottom: 4rem;

    .feedInner {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 2rem 0;
    }
}