.more {
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    line-height: 1;

    .icon {
        cursor: pointer;
        margin-left: 0.3em;
    }

}