.section {
    padding-bottom: 6rem;
    --grid: 3;

    .inner {
        display: -webkit-flex;
        /* Safari */
        display: flex;

        .sidebar {
            width: 25%;
        }

        .body {
            padding-left: 7rem;
            width: 75%;

            .top {
                margin-bottom: 3rem;
                position: relative;
                z-index: 2;

                .count {
                    color: var(--text-grey);
                }

                .right {
                    display: -webkit-flex;
                    /* Safari */
                    display: flex;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);

                    .select {
                        margin-left: 1rem;
                        z-index: 1000;
                    }
                }
            }

            .grid {
                display: grid;
                grid-template-columns: repeat(var(--grid), 1fr);
                grid-gap: 1rem;
            }

            .pagination,
            .btn {
                margin-top: 2rem;
                display: -webkit-flex;
                /* Safari */
                display: flex;
                justify-content: center;
            }
        }
    }
}

@media (max-width: 768px) {
    .section {
        --grid: 2;

        .inner {
            display: block;

            .sidebar {
                width: 100%;
            }

            .body {
                width: 100%;
                padding: 0;

                .top {
                    .right {
                        .quantitySwitcher {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    .section {
        --grid: 2;

        .inner {
            display: block;

            .sidebar {
                width: 100%;
            }

            .body {
                width: 100%;
                padding: 0;
            }
        }
    }
}