.mobileMenu {
    position: fixed;
    top: var(--mobile-header);
    right: 0;
    bottom: 1rem;
    left: 0;
    z-index: 32;
    overflow: auto;
    transition: 0.4s;
    opacity: 0;
    pointer-events: none;
    --nav: 1;
    --grid: 2;

    &.show {
        opacity: 1;
        pointer-events: all;

        .inner {

            .block {
                transform: none;
                pointer-events: all;
            }

            .authBtn {
                pointer-events: all;
            }

        }

    }

    .blackBoard {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .search {
        margin-bottom: 1rem;
    }

    .inner {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat(var(--grid), 1fr);
        position: relative;
        z-index: 2;
        pointer-events: none;

        .col > *:not(:last-child) {
            margin-bottom: 1rem;
        }

        .block {
            backdrop-filter: blur(var(--blur));
            background: var(--grey-bg-opacity);
            border-radius: var(--rad);
            padding: 2.5rem;
            transition: 0.4s;
            transform: scale(0.7);

            .title {
                margin-bottom: 1rem;
                color: var(--text-grey);
                font-weight: 500;
                line-height: 1;
                font-size: var(--fz-small);
                text-transform: uppercase;
                letter-spacing: 0.1em;
            }

            .nav {

                ul {
                    width: calc(100% + 2rem);
                    list-style: none;
                    margin: 0 -1rem;
                    display: grid;
                    grid-gap: 0.2rem;
                    grid-template-columns: repeat(var(--nav), 1fr);

                    li {

                        a {
                            display: flex;
                            align-items: center;
                            text-decoration: none;
                            color: var(--text);
                            padding: 1rem;
                            line-height: 1.3;
                            border-radius: var(--rad);
                            font-weight: 400;
                            transition: 0.2s;

                            .icon {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 3rem;
                                height: 3rem;
                                background: var(--border);
                                border-radius: 3rem;
                                margin-right: 1rem;
                                font-size: 1.2em;
                            }

                            &.activeLink,
                            &:hover {
                                background: var(--theme-opacity);
                            }
                            
                        }

                    }

                }

            }

            &.contacts {

                .text {
                    text-decoration: none;
                    display: block;
                    font-size: var(--fz-larger);
                    white-space: pre-line;

                    &:not(:last-child) {
                        margin-bottom: 0.4rem;
                    }

                }

                .links {
                    display: flex;

                    .line {
                        display: flex;
                        flex-wrap: wrap;
                        margin: -0.3rem -0.6rem;

                        .link {
                            text-decoration: none;
                            border-bottom: 2px solid var(--border);
                            margin: 0.3rem 0.6rem;
                            display: block;
                            line-height: 1.2;
                        }

                    }

                }

            }

        }

    }

}

@media (max-width: 768px) {

    .mobileMenu {
        --grid: 1;
    }

}