.partners {
  padding: 3rem 0;
  width: 100%;

  .partnersInner {
    margin-top: 4rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;

    .partnerCard {
      cursor: pointer;
      flex: 1 1 30%;
      width: 100%;
      background: var(--grey-bg);
      border-radius: var(--rad);
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: opacity 0.2s, transform 0.2s;

      img {
        cursor: pointer;
        width: 25rem;
        height: 25rem;
      }
    }

    .partnerCard:hover {
      transform: translateY(-0.5rem);
      opacity: 0.6;
      transition: opacity 0.2s, transform 0.2s;
    }
  }

  @media (max-width: 768px) {
    .partnersInner {
      flex-direction: column;
      align-items: center;
    }

    .partnerCard {
      flex: 1 1 100%;
    }
  }
}
