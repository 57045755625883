.logo {
    display: flex;
    cursor: pointer;

    svg {
        cursor: pointer;
        fill: var(--text);
        height: var(--logo);
    }

}