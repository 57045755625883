$color: #000;
$color-dark: #fff;
$blue: #2769ec;
$blue-dark: #4381fc;
$orange: #e64b0f;
$orange-dark: #eb6b39;
$red: #e60f2d;
$red-dark: #f74058;
$green: #09c256;
$green-dark: #12d864;

:root {
    --rem: 0.67vw;
    --line-height: 1.6;
    --container: 110rem;
    --logo: 3.5rem;
    --blur: 80px;

    --top-header: 4rem;
    --middle-header: 6rem;
    --bottom-header: 5.5rem;
    --mobile-header: 7rem;

    --fz: 1.4rem;
    --fz-smaller: calc(var(--fz) * 0.9);
    --fz-small: calc(var(--fz) * 0.8);
    --fz-larger: calc(var(--fz) * 1.1);
    --fz-large: calc(var(--fz) * 1.2);

    --field: 4.5rem;
    --field-smaller: 4rem;
    --field-small: 3rem;
    --field-larger: 5rem;
    --field-large: 5.5rem;

    --checkbox: 1.6rem;

    --h1: 4.5rem;
    --h2: 4rem;
    --h3: 3.5rem;
    --h4: 3rem;
    --h5: 2.5rem;
    --h6: 2rem;

    --rad: 0.8rem;
    --rad-smaller: 0.6rem;
    --rad-small: 0.4rem;
    --rad-larger: 1rem;
    --rad-large: 1.2rem;
}

.smaller {
    --fz: var(--fz-smaller);
    --field: var(--field-smaller);
    font-size: var(--fz);
}

.small {
    --fz: var(--fz-small);
    --field: var(--field-small);
    font-size: var(--fz);
}

.larger {
    --fz: var(--fz-larger);
    --field: var(--field-larger);
    font-size: var(--fz);
}

.large {
    --fz: var(--fz-large);
    --field: var(--field-large);
    font-size: var(--fz);
}

.light {
    --bg: #fff;
    --field-bg: var(--bg);
    --bg-opacity: rgba(255, 255, 255, 0.8);
    --blackboard: rgba(0, 0, 0, 0.7);
    --text: #000;
    --contrast: #fff;
    --text-grey: rgba(0, 0, 0, 0.6);
    --text-grey-light: rgba(0, 0, 0, 0.4);
    --contrast: #fff;
    --border: rgba(0, 0, 0, 0.1);
    --field-border: var(--border);
    --grey-bg: #f5f5f7;
    --grey-bg-opacity: rgba(245, 245, 247, 0.7);
    --grey-bg-dark: #e4e4ec;
    --switch-bg: var(--bg);
    --btn-shadow: rgba(0, 0, 0, 0.1);
    --shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);

    --theme: #{$color};
    --theme-dark: #{darken($color, 10%)};
    --theme-light: #{lighten($color, 20%)};
    --theme-focus: #{transparentize($color, 0.7)};
    --theme-opacity: #{transparentize($color, 0.9)};

    --blue: #{$blue};
    --blue-dark: #{darken($blue, 10%)};
    --blue-light: #{lighten($blue, 10%)};
    --blue-focus: #{transparentize($blue, 0.7)};
    --blue-opacity: #{transparentize($blue, 0.9)};

    --orange: #{$orange};
    --orange-dark: #{darken($orange, 10%)};
    --orange-light: #{lighten($orange, 10%)};
    --orange-focus: #{transparentize($orange, 0.7)};
    --orange-opacity: #{transparentize($orange, 0.9)};

    --red: #{$red};
    --red-dark: #{darken($red, 10%)};
    --red-light: #{lighten($red, 10%)};
    --red-focus: #{transparentize($red, 0.7)};
    --red-opacity: #{transparentize($red, 0.9)};

    --green: #{$green};
    --green-dark: #{darken($green, 10%)};
    --green-light: #{lighten($green, 10%)};
    --green-focus: #{transparentize($green, 0.7)};
    --green-opacity: #{transparentize($green, 0.9)};
}

.dark {
    --bg: #191a1b;
    --field-bg: var(--grey-bg-dark);
    --blackboard: rgba(0, 0, 0, 0.3);
    --bg-opacity: rgba(25, 26, 27, 0.8);
    --text: #fff;
    --text-grey: rgba(255, 255, 255, 0.7);
    --text-grey-light: rgba(255, 255, 255, 0.4);
    --contrast: #000;
    --border: rgba(255, 255, 255, 0.1);
    --field-border: transparent;
    --grey-bg-light: #1e2020;
    --grey-bg: #242627;
    --grey-bg-dark: #323536;
    --grey-bg-opacity: rgba(36, 38, 39, 0.7);
    --switch-bg: rgba(255, 255, 255, 0.1);

    --shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    --btn-shadow: rgba(255, 255, 255, 0.1);

    --theme: #{$color-dark};
    --theme-dark: #{darken($color-dark, 10%)};
    --theme-light: #{lighten($color-dark, 10%)};
    --theme-focus: #{transparentize($color-dark, 0.7)};
    --theme-opacity: #{transparentize($color-dark, 0.9)};

    --blue: #{$blue-dark};
    --blue-dark: #{darken($blue-dark, 10%)};
    --blue-light: #{lighten($blue-dark, 10%)};
    --blue-focus: #{transparentize($blue-dark, 0.7)};
    --blue-opacity: #{transparentize($blue-dark, 0.9)};

    --orange: #{$orange-dark};
    --orange-dark: #{darken($orange-dark, 10%)};
    --orange-light: #{lighten($orange-dark, 10%)};
    --orange-focus: #{transparentize($orange-dark, 0.7)};
    --orange-opacity: #{transparentize($orange-dark, 0.9)};

    --green: #{$green-dark};
    --green-dark: #{darken($green-dark, 10%)};
    --green-light: #{lighten($green-dark, 10%)};
    --green-focus: #{transparentize($green-dark, 0.7)};
    --green-opacity: #{transparentize($green-dark, 0.9)};

    --red: #{$red-dark};
    --red-dark: #{darken($red-dark, 10%)};
    --red-light: #{lighten($red-dark, 10%)};
    --red-focus: #{transparentize($red-dark, 0.7)};
    --red-opacity: #{transparentize($red-dark, 0.9)};

    color: var(--text);
    color-scheme: dark;
}

.redTheme,
[color="red"] {
    --theme: var(--red);
    --theme-dark: var(--red-dark);
    --theme-light: var(--red-light);
    --theme-focus: var(--red-focus);
    --theme-opacity: var(--red-opacity);
}

.orangeTheme,
[color="orange"] {
    --theme: var(--orange);
    --theme-dark: var(--orange-dark);
    --theme-light: var(--orange-light);
    --theme-focus: var(--orange-focus);
    --theme-opacity: var(--orange-opacity);
}

.blueTheme,
[color="blue"] {
    --theme: var(--blue);
    --theme-dark: var(--blue-dark);
    --theme-light: var(--blue-light);
    --theme-focus: var(--blue-focus);
    --theme-opacity: var(--orbluenge-opacity);
}

.greenTheme,
[color="green"] {
    --green: var(--green);
    --green-dark: var(--green-dark);
    --green-light: var(--green-light);
    --green-focus: var(--green-focus);
    --green-opacity: var(--green-opacity);
}

@media (max-width: 768px) {

    :root {
        --container: 40rem;
        --rem: 2.2vw;
    }

}

@media (min-width: 768px) and (max-width: 1200px) {

    :root {
        --container: 62rem;
        --rem: 1.4vw;
    }

}

@media (min-width: 1200px) and (max-width: 1400px) {

    :root {
        --rem: 0.74vw;
    }

}