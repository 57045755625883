.slider {
    overflow: hidden;
    padding: calc(var(--field-smaller) + 4rem) 0;


    .inner {
        height: 50rem;
        margin-top: 4rem;
        position: relative;


        .sliderNav {

            position: absolute;
            pointer-events: none;
            padding: 2rem;
            right: 0;
            bottom: 0;
            z-index: 3;
            display: flex;

            &>* {
                pointer-events: all;

                &:not(:last-child) {
                    margin-right: 1rem;
                }
            }
        }


    }
}

@media (max-width: 768px) {

    .slider {
        .inner {
            height: 70rem;

            .sliderNav {
                padding: 3rem;
            }


        }
    }
}