.dropdown {
    // border: 1px solid var(--border);
    background: var(--grey-bg-opacity);
    backdrop-filter: blur(var(--blur));
    width: fit-content;
    border-radius: var(--rad);
    box-shadow: var(--shadow);
    z-index: 32;
    position: relative;
    min-width: 100%;
    padding: 2rem;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
            font-weight: 600;
            font-size: var(--fz-larger);

            span {
                color: var(--text-grey);
            }

        }

    }

    .body {
        padding: 1.5rem 0 1rem 0;

        .item {

            &:not(:last-child) {
                margin-bottom: 3rem;
            }
    
            .info {
                position: relative;
                display: flex;
                align-items: center;
    
                .title {
                    font-weight: 500;
                    line-height: 1;
    
                    &:not(:last-child) {
                        margin-bottom: 0.4rem;
                    }
    
                }
    
                .subtitle {
                    line-height: 1;
                    color: var(--text-grey);
                    font-size: var(--fz-small);
                }
    
                .name {
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    color: var(--text);
                    white-space: nowrap;
                    padding-right: 2rem;
                    flex-grow: 1;
    
                    .image {
                        flex-shrink: 0;
                        margin-right: 1rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 4rem;
                        height: 4rem;
    
                        .icon {
                            display: flex;
                            font-size: 4rem;
                            color: var(--text-grey);
                        }
    
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
    
                    }
    
                    .text {
                        width: 0;
                        flex-grow: 1;
    
                        & > * {
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
    
                    }
    
                }
    
                .quantity {
                    width: 11rem;
                    padding-right: 3rem;
                }
    
                .removeItem {
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    display: flex;
                    align-items: center;
                    color: var(--text-grey);
                    padding: 0 1rem;
                    margin-right: -1rem;
                }
    
            }
    
        }

    }

    .btns {
        margin-top: 1rem;
        display: flex;

        .btn {
            width: 0;
            flex-grow: 1;

            &:not(:last-child) {
                margin-right: 1rem;
            }

        }

    }

}