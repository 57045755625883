.cartBtn {
    position: relative;
    

    .cartBtnWrapper {
        cursor: pointer;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: var(--text);
    
        .icon {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 1rem;
            font-size: 2rem;
            background: var(--grey-bg-dark);
            width: var(--field-smaller);
            height: var(--field-smaller);
            border-radius: var(--rad);
            position: relative;
    
            &[data-count]:not([data-count="0"]):after {
                cursor: pointer;
                content: attr(data-count);
                position: absolute;
                top: 0;
                right: 0;
                background: var(--theme);
                color: var(--contrast);
                transform: translate(50%, -40%);
                line-height: 20px;
                font-size: 11px;
                font-weight: 600;
                min-width: 20px;
                padding: 0 3px;
                text-align: center;
                border-radius: 100%;
                transition: 0.2s;
            }
    
        }
    
        .textWrapper {
            line-height: 1;
    
            .price {
                font-weight: 500;
                font-size: var(--fz-larger);
                margin-bottom: 0.2em;
            }
    
            .text {
                font-size: var(--fz-smaller);
                color: var(--text-grey);
            }
    
        }
    
    }

    .dropdown {
        position: absolute;
        top: 100%;
        right: 0;
        width: 38rem;
        padding-top: 1rem;
        transition: 0.2s;

        &:not(.show) {
            opacity: 0;
            pointer-events: none;
        }

    }

}