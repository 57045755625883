.textBlock {
    --h1: 2.8rem;
    --h2: 2.6rem;
    --h3: 2.4rem;
    --h4: 2.2rem;
    --h5: 2rem;
    --h6: 1.8rem;

    a {
        color: inherit;
        text-decoration: none;
        border-bottom: 1px solid var(--border);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        
        &:not(:first-child) {
            margin-top: 1em;
        }

    }

    & > *:not(:last-child) {
        margin-bottom: 1.2rem;
    }

    ol {
        padding-left: 2.5rem;

        li {

            &:not(:last-child) {
                margin-bottom: 0.3em;
            }

            &::marker {
                color: var(--text-grey);
                font-weight: 500;
            }

        }

    }

    ul {
        padding-left: 2.5rem;

        li {

            &:not(:last-child) {
                margin-bottom: 0.3em;
            }

            &::marker {
                color: var(--text-grey);
                font-weight: 500;
            }

        }

    }

    blockquote {
        background: var(--grey-bg);
        padding: 1rem 1.5rem;
        border-radius: var(--rad-small);
        border-left: 3px solid var(--border);
    }

}