.closeBtn {
    --size: calc(var(--field) / 2);
    width: var(--size);
    height: var(--size);
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--grey-bg-dark);
    color: var(--text-grey-light);
    border-radius: 100%;
    font-size: calc(var(--size) * 0.6);
}