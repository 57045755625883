.post {
    width: 100%;
    height: 50rem;
    background: var(--grey-bg);
    border-radius: var(--rad);

    @media (max-width: 768px) {
        height: 70rem;
    }

    .inner {
        height: 100%;
        width: 100%;
        display: grid;
        align-items: center;
        grid-template-columns: repeat(5, 1fr);
        gap: 0 1rem;

        @media (max-width: 768px) {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(5, 1fr);
            gap: 1rem 0;
        }

        .image {
            border-radius: var(--rad-large) 0 0 var(--rad-large);
            width: 100%;
            height: 100%;
            grid-column: span 2;
            background: #000;
            background-size: cover;
            background-position: center;

            @media (max-width: 768px) {

                border-radius: 1rem 1rem 0 0;
                grid-row: span 2;
            }
        }

        .brief {
            width: 100%;
            padding: 3rem;
            grid-column: span 3;
            display: flex;
            flex-direction: column;
            gap: 2rem 0;
            align-items: flex-start;

            @media (max-width: 768px) {
                padding: 2rem;
                grid-row: span 3; // Текст будет на второй строке
            }

            h3 {
                max-width: 80%;

                @media (max-width: 768px) {
                    max-width: 100%;
                    font-size: 2em;
                }
            }

            p {
                max-width: 100%;
            }
        }
    }
}