.products {
    .name {
        width: auto;
        flex-grow: 0.5;
    }

    .quantity {
        width: 16rem;
    }

    .price {
        width: 14rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-end;
    }

    .discounted {
        display: flex;
        align-items: center;
        gap: 1rem;

        .percentage {
            background: linear-gradient(135deg, #ff7e5f, #feb47b);
            color: white;
            padding: 0.6rem 0.4rem;
            border-radius: 5px;
            font-weight: bold;
            font-size: 1rem;
        }

        .priceBlock {
            display: flex;
            flex-direction: column;

            .newPrice {
                font-weight: bold;
                font-size: 1.4rem;
                color: var(--accent-color);
            }

            .oldPrice {
                font-weight: bold;
                font-size: 1.4rem;
                color: var(--text-grey);
                text-decoration: line-through;
            }
        }
    }

    .header {
        display: flex;
        align-items: center;
        font-size: var(--fz-smaller);
        color: var(--text-grey);
        margin-bottom: 2rem;
    }

    .item {
        &:not(:last-child) {
            margin-bottom: 3rem;
        }

        .info {
            position: relative;
            display: flex;
            align-items: center;

            .title {
                font-weight: 500;
                font-size: var(--fz-larger);
                line-height: 1;

                &:not(:last-child) {
                    margin-bottom: 0.6rem;
                }
            }

            .subtitle {
                line-height: 1;
                color: var(--text-grey);
                font-size: var(--fz-smaller);
            }

            .name {
                display: flex;
                align-items: center;
                text-decoration: none;
                color: var(--text);
                white-space: nowrap;
                padding-right: 2rem;

                .image {
                    flex-shrink: 0;
                    margin-right: 1.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 5rem;
                    height: 6rem;

                    .icon {
                        display: flex;
                        font-size: 4rem;
                        color: var(--text-grey);
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                .text {
                    width: 0;
                    flex-grow: 1;

                    &>* {
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
            }

            .quantity {
                padding-right: 4rem;
            }

            .removeItem {
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                display: flex;
                align-items: center;
                color: var(--text-grey);
                padding: 0 1rem;
                margin-right: -1rem;
            }
        }
    }
}

@media (max-width: 768px) {
    .products {
        .item {
            .info {
                .name {
                    .image {
                        margin-right: 1rem;
                        width: 4rem;
                        height: 5rem;
                    }
                }

                .quantity {
                    padding-right: 1.5rem;
                    width: 13rem;
                }

                .price {
                    width: 11rem;
                }
            }
        }
    }
}