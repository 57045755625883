.section {
    padding: 4rem 0;

    .title {
        margin-bottom: 1rem;
    }

    p {
        font-weight: 500;
        color: var(--text-grey);
        font-size: var(--fz-larger);
        margin-bottom: 2rem;
    }

    .btns {
        display: flex;

        & > *:not(:last-child) {
            margin-right: 1rem;
        }

    }

}