.footer {
    background: var(--bg);
    padding: 2rem 0 3rem 0;

    .topFooter {
        border-bottom: 1px solid var(--border);
        padding-bottom: 4rem;
        margin-bottom: 3rem;
        
        .inner {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
    
            .about {
                --logo: 4rem;
                
                p {
                    margin-top: 2rem;
                    width: 40rem;
                    color: var(--text-grey);
                }
    
                nav {
                    margin-top: 2rem;
    
                    ul {
                        display: flex;
                        list-style: none;
                        flex-wrap: wrap;
                        margin: -0.5rem -1.5rem;
                        width: calc(100% + 3rem);
                        
                        li {
                            margin: 0.5rem 1.5rem;
                        }
    
                        a {
                            font-weight: 500;
                            color: var(--text);
                            text-decoration: none;
                            position: relative;

                            &:after {
                                content: '';
                                position: absolute;
                                right: 0;
                                bottom: 0;
                                height: 1px;
                                background: var(--text);
                                width: 0;
                                transition: 0.3s;
                            }

                            &:hover {

                                &:after {
                                    left: 0;
                                    width: 100%;
                                }

                            }

                        }
    
                    }
    
                }
    
            }  

            .contacts {
                line-height: 1;

                a {
                    text-decoration: none;
                    display: block;
                }

                .phone {
                    font-weight: 600;
                    font-size: var(--h4);
                    margin-bottom: 0.3em;
                }

                .email {
                    font-size: var(--h6);
                    color: var(--text-grey);
                    font-weight: 500;
                }

            }

        }

    }

    .bottomFooter {
        color: var(--text-grey);

        .inner {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .social {
                display: flex;
                align-items: center;
                gap: 1rem;

                & a > *{
                    border: 1px solid var(--border);
                    font-size: var(--h4);
                    padding: 0.7rem;
                    border-radius: 0.5rem;
                }


            }

        }

    }

}

@media (max-width: 768px) {

    .footer {

        .topFooter {

            .inner {
                display: block;

                & > *:not(:last-child) {
                    margin-bottom: 5rem;
                }

            }

        }

        .bottomFooter {

            .inner {
                flex-direction: column;
                align-items: flex-start;

                & > *:not(:last-child) {
                    margin-bottom: 1rem;
                }

            }
    
        }

    }

}

@media (min-width: 768px) and (max-width: 1200px) {

    .footer {

        .topFooter {

            .inner {
                display: block;

                & > *:not(:last-child) {
                    margin-bottom: 5rem;
                }

            }

        }

    }

}