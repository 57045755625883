.badges {
    position: absolute;
    top: 1rem;
    left: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.discount {
    border-radius: 1rem;
    padding: 1rem 1rem;
    line-height: 1;
    font-weight: 400;
    color: var(--text-grey);
    color: white;
    z-index: 1;
    font-weight: bold;
    background: linear-gradient(135deg, #ff7e5f, #feb47b);
}

.installment {
    border-radius: 1rem;
    padding: 1rem 1rem;
    line-height: 1;
    font-weight: 400;
    color: var(--text-grey);
    z-index: 1;
    color: white;
    font-weight: bold;
    background: linear-gradient(to right, #FC96FC, #959EF1, #0DC9D4);
}

.product {
    cursor: pointer;
    display: block;
    overflow: hidden;
    border-radius: var(--rad);
    position: relative;
    padding: 2.5rem;
    color: var(--text);
    text-decoration: none;
    text-align: center;
    user-select: none;
    background: var(--grey-bg);
    min-height: 450px;
  

    .blur {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-position: center;
        background-size: cover;
        filter: blur(6rem);
        opacity: 0.2;
        transform: scale(1.2);
        transition: 0.7s;
    }

    .image {
        cursor: pointer;
        height: 30rem;
        display: -webkit-flex;
        /* Safari */
        display: flex;
        margin-bottom: 1rem;
        position: relative;
        z-index: 1;
        transition: 0.7s;
        padding: 1rem;

        .noImage {
            cursor: pointer;
            height: 100%;
            width: 100%;
            display: -webkit-flex;
            /* Safari */
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12rem;
            opacity: 0.1;
        }

        img {
            cursor: pointer;
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }

    .content {
      
        z-index: 1;
        cursor: pointer;
    }

    .title {
       
        font-weight: 600;
        line-height: 1.3;
        font-size: var(--fz-larger);
        margin-bottom: 0.4em;
    }

    .prices {
      
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .price {
            line-height: 1;
            font-weight: 400;
            color: var(--text-grey);
        }

        .oldPrice {
            line-height: 1;
            font-weight: 600;
            color: var(--text-grey);
            text-decoration: line-through;
        }
        .noStock {
            padding: 1rem 0;
                line-height: 1;
                font-weight: 800;
                color: var(--text-grey);
        }
    }

    &:hover {

        .blur {
            opacity: 0.1;
        }

        .image {
            transform: scale(1.08);
        }
    }
}

@media (max-width: 768px) {
    .product {
            min-height: 200px;
        .image {
            height: 26rem;
        }

        .title {
            font-size: var(--fz-large);
        }
    }
}