.tag {
    width: fit-content;
    font-size: var(--fz);
    padding: 0.5em 0.7em;
    border-radius: 3rem;
    line-height: 1;
    border: 1px solid transparent;
    text-decoration: none;
    color: var(--text);
    display: flex;
    align-items: center;

    &.primary {
        color: var(--contrast);
        background: var(--theme);
    }

    &.small {
        --fz: var(--fz-small);
    }

    &.smaller {
        --fz: var(--fz-smaller);
    }

    &.larger {
        --fz: var(--fz-larger);
    }

    &.large {
        --fz: var(--fz-large);
    }

    &.outline {
        border-color: var(--border);
    }

    &.themeOutline {
        border-color: var(--theme);
        color: var(--theme);
    }

    &.grey {
        background: var(--grey-bg-dark);
    }

    .close {
        display: flex;
        margin-left: 0.2rem;
        color: var(--text-grey);
    }

}