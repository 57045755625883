.sliderWrapper {

    .slider {
        margin-bottom: 2rem;
    }

    .sliderLine {
        height: 4px;
        background: var(--grey-bg);
        border-radius: 3px;
        position: relative;

        .sliderTrack:nth-child(2) {
            position: absolute;
            top: 0;
            bottom: 0;
            background: var(--border);
        }

        .sliderThumb {
            height: 16px;
            width: 16px;
            position: absolute;
            top: -6px;
            background: var(--grey-bg-dark);
            border: 2px solid var(--border);
            border-radius: 100%;
        }

    }

    .inputs {
        display: flex;
        align-items: center;

        .input {
            width: 0;
            flex-grow: 1;
        }

        .sep {
            margin: 0 1rem;
        }

    }

}