.list {

    & > *:not(:last-child) {
        margin-bottom: 2rem;
    }

}

.item {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
        margin-right: 2rem;
    }

    .title {
        font-weight: 600;
        margin-bottom: 0.6rem;
        line-height: 1;
        font-size: var(--fz-larger);
    }

    .label {
        color: var(--text-grey);
        font-size: var(--fz-smaller);
        line-height: 1;
    }

    .name {
        width: 0;
        flex-grow: 1;
        display: flex;
        align-items: center;
        color: var(--text);
        text-decoration: none;

        .image {
            flex-shrink: 0;
            width: var(--field);
            height: var(--field-large);
            background: var(--bg);
            border-radius: var(--rad);
            padding: 0.5rem;
            margin-right: 1.5rem;

            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }

        }

        .text {
            width: 0;
            flex-grow: 1;
            white-space: nowrap;
            line-height: 1;

            & > * {
                overflow: hidden;
                text-overflow: ellipsis;
            }

        }

    }

    .quantity {
        width: 6rem;
    }

    .price {
        width: 12rem;
    }

}