.list {

    .inner {
        .icon {
            color: var(--text-grey);
            font-size: large;
            align-self: center;
            margin-left: 1rem;

        }

        .icon:hover {
            transform: scale(1.1);
            transition: all 0.3s;

        }

        &>*:not(:last-child) {
            margin-bottom: 0.9rem;
        }

        .info {
            background-color: var(--blackboard);
            height: 0;
            opacity: 0;
            transform: scale(0);

            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 2rem;
            transition: 0.3s ease-in-out;

            .open {
                background-color: #333;
                opacity: 1;
                transform: scale(1);
                margin: 2rem 0;


                display: flex;
                align-items: center;
                flex-direction: column;
                gap: 2rem;
                transition: 0.3s ease-in-out;

            }
        }

        .item {
            line-height: 1.3;
            display: flex;
            align-items: baseline;

            .title {
                color: var(--text-grey);
            }

            .line {
                margin: 0 1rem;
                flex-grow: 1;
                border-bottom: 1px dashed var(--border);
            }

            &.large {
                font-size: var(--fz-larger);
                font-weight: 600;

                .title {
                    color: var(--text);
                }

            }

        }

    }

    &.two {

        .inner {
            margin-bottom: -0.9rem;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            &>* {
                margin-bottom: 0.9rem;
            }

            .item {
                width: calc(50% - 2rem);
            }

        }

    }

}