.map {
    width: 100%;
    overflow: hidden;
    height: 100%;
    position: relative;
    filter: grayscale(1);

    .inner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    [class*="controls-pane"],
    [class*="copyrights-pane"] {
        display: none;
    }

    [class*="ground-pane"] {
        filter: invert(1) contrast(0.9);
    }

    [class*="map-bg"] {
        background: var(--bg);
    }

}