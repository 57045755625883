.section {
    
    padding: 5rem 0;

    .inner {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1rem;
       


        .description {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding-right: 5rem;

            & > *:not(:last-child) {
                margin-bottom: 2rem;
            }

            .text {
                color: var(--text-grey);
            }

        }

        .card {
            cursor: pointer;
            display: block;
            text-decoration: none;
            position: relative;

            &:before {
                  
                content: '';
                position: absolute;
                background: var(--grey-bg-dark);
                left: 5%;
                right: 5%;
                top: 0;
                bottom: 0;
                border-radius: var(--rad);
                z-index: 1;
                transition: 0.5s;
            }

            &:after {
                  
                content: '';
                position: absolute;
                background: var(--grey-bg);
                left: 10%;
                right: 10%;
                top: 0;
                bottom: 0;
                border-radius: var(--rad);
                transition: 0.5s;
            }

            .cardInner {
                  cursor: pointer;
                border-radius: var(--rad);
                overflow: hidden;
                position: relative;
                height: 40rem;
                z-index: 3;
                transition: 0.5s;
            }

            .image {
                cursor: pointer;
                display: flex;
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                right: 0;
                filter: brightness(0.7);
                transition: 0.7s;

                img {
                      cursor: pointer;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }

            }

            .content {
                cursor: pointer;
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                z-index: 2;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 4rem;

                .title {
                    font-size: var(--h5);
                    font-weight: 600;
                    line-height: 1.3;
                    margin-bottom: 0.4rem;
                }

                .text {
                    margin-bottom: 1rem;
                    font-size: var(--fz-smaller);
                    color: var(--text-grey);
                }

            }

            &:hover {
                .cardInner {
                    transform: translateY(-1rem);
                }

                &:after {
                    transform: translateY(1rem);
                }

                .image {
                    filter: brightness(0.6);
                    transform: scale(1.1);
                }

            }

        }

    }

}

@media (max-width: 768px) {

    .section {

        .inner {
            display: block;

            & > *:not(:last-child) {
                margin-bottom: 4rem;
            }

            .description {
                padding: 0 4rem;
            }

        }

    }

}

@media (min-width: 768px) and (max-width: 1200px) {

    .section {

        .inner {
            grid-template-columns: repeat(2, 1fr);

            .description {
                grid-column-end: span 2;
                padding: 0 4rem 4rem 4rem;
            }

        }

    }

}