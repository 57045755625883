.block {
    text-align: center;
    --size: 0px;
    line-height: 1;
    color: var(--text-grey, #333);
    /* Provide a fallback color value */
}

.icon {
    font-size: calc(var(--size, 0) * 5);
    /* Provide a fallback value for --size */
    margin-bottom: 0.1em;
}

.text {
    font-weight: 600;
    font-size: calc(var(--size, 0) * 1.2);
    /* Provide a fallback value for --size */
}

/* Rest of your CSS without prefixes, as they are not needed */
.large {
    --size: var(--fz-large);
}

.larger {
    --size: var(--fz-larger);
}

.primary {
    --size: var(--fz);
}

.smaller {
    --size: var(--fz-smaller);
}

.small {
    --size: var(--fz-small);
}

.padding {
    padding: calc(var(--size, 0) * 2) 0;
    /* Provide a fallback value for --size */
}