.form {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr;

    .full {
        grid-column-end: span 2;
        & p {
            font-size: var(--fz-larger);
        }
    }

}

@media (max-width: 768px) {

    .form {
        display: block;

        & > *:not(:last-child) {
            margin-bottom: 2rem;
        }

    }

}