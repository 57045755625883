.installment {
    display: flex;
    text-align: center;
    justify-content: center;
    border-radius: var(--rad);
    padding: 1.7rem;
    line-height: 1;
    font-weight: 400;
    color: white;
    background: linear-gradient(to right, #FC96FC, #959EF1, #0DC9D4);
    width: 100%;
    cursor: pointer;
    font-weight: 500;
    font-size: medium;
    border: none;
}

.payOrder {
    display: flex;
    text-align: center;
    justify-content: center;
    border-radius: var(--rad);
    padding: 1.7rem;
    line-height: 1;
    font-weight: 400;
    color: white;
    background: var(--grey-bg);
    width: 100%;
    cursor: pointer;
    font-weight: 500;
    font-size: large;
    border: none;
    text-decoration: none;
}

.sidebar {
    background: var(--grey-bg);
    border-radius: var(--rad-larger);

    .row {
        padding: 3rem;

        &:not(:last-child) {
            border-bottom: 1px solid var(--border);
        }

    }

        .promocode {
            width: 100%;
            margin-bottom: 2rem;
            display: grid;
            grid-template-columns: repeat(8, 1fr);
            align-items: center;
            justify-content: space-between;
            gap: 1rem;
            & > div.inputWrapper {
                width: 100%;
                grid-column: span 7;
            }
    
            & > div.buttonWrapper {
                grid-column: span 1;
            }
        }

    .title {
        line-height: 1.3;
        margin-bottom: 2rem;
    }

    .params {
        margin-bottom: 2.5rem;
    }

    .btns>*:not(:last-child) {
        margin-bottom: 1rem;
    }

    .smallTitle {
        font-size: var(--fz-larger);
        font-weight: 500;
        margin-bottom: 0.5rem;
    }

    .links {

        .link {
            display: flex;
            align-items: center;
            text-decoration: none;
            line-height: 1;
            padding: 1rem;
            margin-left: -1rem;
            margin-right: -1rem;
            border-radius: var(--rad);
            transition: 0.2s;

            .icon {
                display: flex;
                margin-right: 0.8rem;
                font-size: 1.2em;
                color: var(--text-grey);
            }

            .arrow {
                display: flex;
                color: var(--text-grey);
                margin-left: auto;
                transition: 0.4s;
            }

            &:hover {
                background: var(--border);

                .arrow {
                    transform: translateX(-0.5rem);
                }

            }

        }

    }

}