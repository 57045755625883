.accords {

    .item {
        cursor: pointer;
        background: var(--grey-bg);
        border-radius: var(--rad);

        &:not(:last-child) {
            margin-bottom: 2px;
             cursor: pointer;
        }

        .title {
             cursor: pointer;
            line-height: 1.3;
            padding: 1.6rem 2rem;
            font-weight: 400;
            display: flex;
            align-items: center;
            user-select: none;

            .text {
                 cursor: pointer;
                width: 0;
                flex-grow: 1;
                padding-right: 2rem;
            }

            .indicator {
                position: relative;
                width: 1rem;
                height: 1px;
                opacity: 0.8;

                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    height: 1px;
                    top: 0;
                    background: var(--text);
                }

                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    height: 1px;
                    top: 0;
                    background: var(--text);
                    transform: rotate(90deg);
                    transition: 0.2s;
                }

            }

        }

        .content {
            display: none;
            
            transition: all 0.3s ease-in-out;
            padding: 0 2rem 2rem 2rem;
            font-size: var(--fz-smaller);
            color: var(--text-grey);
        }

        &.show {

            .title {

                .indicator:after {
                    transform: rotate(0deg);
                }

            }

            .content {
                display: block;
             
               transition: all 0.3s ease-in-out;
            }

        }

    }

}