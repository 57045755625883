.pagination {
    padding-top: 5rem;
    display: flex;


    .elipsis {
        font-size: 16px;
        font-weight: 600;
        height: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: var(--rad-smaller);
        color: var(--text-grey);
        padding: 2.3rem 2rem;
        transition: 0.3s;

        @media (max-width: 767px) {
            // Adjust the size for smaller screens
            font-size: 10px;
            padding: 1rem 1.2rem;
        }
    }

    .item {
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
        height: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: var(--rad-smaller);
        color: var(--text-grey);
        border: 1px solid var(--grey-bg);
        padding: 2.3rem 2rem;
        transition: 0.3s;

        @media (max-width: 767px) {
            font-size: 10px;
            padding: 2rem 1.2rem;
        }

        &:hover {
            border: 1px solid var(--text-grey);
            transition: 0.3s;
        }

        &:not(:last-child) {
            margin-right: 2rem;

            @media (max-width: 767px) {
                margin-right: 0.5rem;
            }
        }

        &.arrow {
            background: var(--theme);
            color: var(--contrast);
            font-weight: 700;

            @media (max-width: 767px) {
                // Adjust the size for smaller screens
                font-size: 10px;
                padding: 2rem 1.2rem;
            }
        }

        &.active {
            background: var(--theme);
            color: var(--contrast);
        }
    }
}