.form {
    position: relative;
}

.title {
    text-align: center;
    margin-bottom: 2rem;
}

.alt {
    text-align: center;
    font-size: var(--fz-smaller);
}

.hidden {
    display: none;
}