@font-face {
    font-family: 'icomoon';
    src: url('../assets/icons/icomoon.eot?runczu');
    src: url('../assets/icons/icomoon.eot?runczu#iefix') format('embedded-opentype'),
        url('../assets/icons/icomoon.ttf?runczu') format('truetype'),
        url('../assets/icons/icomoon.woff?runczu') format('woff'),
        url('../assets/icons/icomoon.svg?runczu#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-menu:before {
    content: "\e931";
}

.icon-calendar:before {
    content: "\e930";
}

.icon-off:before {
    content: "\e92f";
}

.icon-lock:before {
    content: "\e92d";
}

.icon-key:before {
    content: "\e92e";
}

.icon-close-alt:before {
    content: "\e92b";
}

.icon-check-alt:before {
    content: "\e92c";
}

.icon-refresh:before {
    content: "\e92a";
}

.icon-option:before {
    content: "\e929";
}

.icon-user:before {
    content: "\e926";
}

.icon-place:before {
    content: "\e927";
}

.icon-chat:before {
    content: "\e928";
}

.icon-verify:before {
    content: "\e920";
}

.icon-box:before {
    content: "\e923";
}

.icon-qr:before {
    content: "\e924";
}

.icon-kaspi:before {
    content: "\e925";
}

.icon-instagram:before {
    content: "\e91c";
}

.icon-twitter:before {
    content: "\e91d";
}

.icon-whatsapp:before {
    content: "\e91e";
}

.icon-vk:before {
    content: "\e91f";
}

.icon-youtube:before {
    content: "\e921";
}

.icon-facebook:before {
    content: "\e922";
}

.icon-arrow-up:before {
    content: "\e900";
}

.icon-arrow-down:before {
    content: "\e901";
}

.icon-arrow-left:before {
    content: "\e902";
}

.icon-arrow-right:before {
    content: "\e903";
}

.icon-select:before {
    content: "\e904";
}

.icon-down:before {
    content: "\e905";
}

.icon-left:before {
    content: "\e906";
}

.icon-up:before {
    content: "\e907";
}

.icon-right:before {
    content: "\e908";
}

.icon-filter:before {
    content: "\e909";
}

.icon-sun:before {
    content: "\e90a";
}

.icon-moon:before {
    content: "\e90b";
}

.icon-link:before {
    content: "\e90c";
}

.icon-search:before {
    content: "\e90d";
}

.icon-image:before {
    content: "\e90e";
}

.icon-close:before {
    content: "\e90f";
}

.icon-open:before {
    content: "\e910";
}

.icon-check:before {
    content: "\e911";
}

.icon-home:before {
    content: "\e912";
}

.icon-edit:before {
    content: "\e913";
}

.icon-help:before {
    content: "\e914";
}

.icon-error:before {
    content: "\e915";
}

.icon-success:before {
    content: "\e916";
}

.icon-warning:before {
    content: "\e917";
}

.icon-email:before {
    content: "\e918";
}

.icon-inbox:before {
    content: "\e919";
}

.icon-phone:before {
    content: "\e91a";
}

.icon-discount:before {
    content: "\e916";
}

.icon-cart:before {
    content: "\e91b";
}