.switcher {
    --padding: 3px;
    --size: calc(var(--field) - (var(--padding) * 2));
    height: var(--field);
    background: var(--border);
    border-radius: var(--rad);
    width: -webkit-fit-content;
    /* Safari */
    width: fit-content;
    padding: var(--padding);
    font-size: var(--fz);

    &.full {
        width: 100%;
    }

    .innerWrapper {
        position: relative;
        display: -webkit-flex;
        /* Safari */
        display: flex;
    }

    .item {
        height: var(--size);
        display: -webkit-flex;
        /* Safari */
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: calc(var(--rad) - var(--padding));
        transition: 0.2s;
        color: var(--text-grey);
        position: relative;
        z-index: 2;
        flex-grow: 1;

        .icon {
            width: var(--size);
            font-size: 1.3em;
            display: -webkit-flex;
            /* Safari */
            display: flex;
            justify-content: center;
        }

        .text {
            padding: 0 1em;
        }

        &.active {
            background: var(--switch-bg);
            color: var(--theme);
        }

        &.grow {
            width: 0;
        }
    }

    &.small {
        --fz: var(--fz-small);
        --field: var(--field-small);
    }

    &.smaller {
        --fz: var(--fz-smaller);
        --field: var(--field-smaller);
    }

    &.large {
        --fz: var(--fz-large);
        --field: var(--field-large);
    }

    &.larger {
        --fz: var(--fz-larger);
        --field: var(--field-larger);
    }
}