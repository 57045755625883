.pageHeader {
    padding-bottom: 5rem;
    display: flex;
    align-items: center;

    .inner {
        display: flex;

        .titleWrapper {
            flex-grow: 1;

            & > *:not(:last-child) {
                margin-bottom: 1rem;
            }

            span {
                color: var(--text-grey);
            }

        }

        .after {
            margin-left: 5rem;
        }

    }

}

@media (max-width: 768px) {

    .pageHeader {
        min-height: auto;
        padding: 2rem 0;

        .inner {
            display: block;

            .title {
                width: 100%;
            }

            .after {
                margin-left: 0;
                margin-top: 1rem;
            }

        }

    }

}