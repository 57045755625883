.slide {
    background: var(--bg);
    display: block;
    border-radius: var(--rad);
    position: relative;
    text-decoration: none;
    color: var(--text);
    overflow: hidden;

    .banner {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: cover;
        background-repeat: no-repeat;
        cursor: pointer;
    }

    .image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-position: right;
        background-size: cover;
        filter: brightness(0.6);
        transition: 0.7s;
    }

    .content {
        min-height: var(--height);
        width: 55%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 5rem;
        position: relative;
        z-index: 2;

        .title {
            font-size: var(--h2);
            line-height: 1.3;
            font-weight: 600;

            &:not(:first-child) {
                margin-top: 0.6rem;
            }
        }

        .text {
            font-weight: 500;
            font-size: var(--fz-large);
            color: var(--text-grey);

            &:not(:first-child) {
                margin-top: 0.2rem;
            }
        }

        .btn {
            &:not(:first-child) {
                margin-top: 1.5rem;
               
            }
            
        }
    }

    &:hover {
        .image {
            // background-position: right;
            filter: brightness(0.8);
            transform: scale(1.1);
            cursor: pointer;
        }
    }
}

.section {
    --height: 40rem;
    overflow: hidden;

    .inner {
        position: relative;

        .sliderNav {
            position: absolute;
            pointer-events: none;
            padding: 2rem;
            right: 0;
            bottom: 0;
            z-index: 3;
            display: flex;

            &>* {
                pointer-events: all;

                &:not(:last-child) {
                    margin-right: 1rem;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .slide {
        .content {
            width: 100%;
            padding: 3rem;

            .title {
                font-size: var(--h4);
            }
        }
             
                    .image {
                        background-position: 83% 90%;
                        filter: brightness(0.8);
                      
                        cursor: pointer;
                    }
                
    }

    .section {
        .inner {
            .sliderNav {
                padding: 3rem;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    .slide {
        .content {
            width: 80%;
            padding: 4rem;

            .title {
                font-size: var(--h3);
            }
        }
    }
}