.filters {
    /* position: sticky;
    top: 5rem;
    height: 50rem;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 2rem; */
    z-index: 1000;

    .mobileHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 1;
        background: var(--grey-bg);
        position: sticky;
        top: 0;
        z-index: 4;
        display: none;
        padding: 3rem 0;

        .title {
            font-size: var(--h5);
            font-weight: 500;
        }

    }

    .appliedFilters {
        margin-bottom: 2rem;
        border-bottom: 1px solid var(--border);
        padding-bottom: 2rem;

        .header {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            margin-bottom: 1rem;

            .title {
                font-weight: 500;
            }

            .clear {
                color: var(--text-grey);
            }

        }

        .body {
            display: flex;

            .line {
                display: flex;
                flex-wrap: wrap;
                margin: -0.3rem;

                &>* {
                    margin: 0.3rem;
                }

            }

        }

    }

    .filtersBody {
        margin: -2rem 0;

        .item {
            user-select: none;

            &:not(:last-child) {
                border-bottom: 1px solid var(--border);
            }

            .header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                line-height: 1;
                padding: 2rem 0;

                .title {
                    font-size: var(--fz-larger);
                    font-weight: 500;
                    position: relative;
                    padding-right: 0.5rem;

                    .applied {
                        position: absolute;
                        left: 100%;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: var(--fz-smaller);
                        background: var(--theme);
                        color: var(--contrast);
                        line-height: 1.8rem;
                        min-width: 1.8rem;
                        text-align: center;
                        padding: 0 0.2rem;
                        border-radius: 0.9rem;
                    }

                }

                .arrow {
                    color: var(--text-grey);
                    display: flex;
                    transition: 0.2s;
                }

            }

            .body {
                display: none;
                padding-bottom: 2rem;

                .checkboxes {

                    &>*:not(:last-child) {
                        margin-bottom: 1rem;
                    }

                }

            }

            &.show {

                .header {
                    padding-bottom: 0;
                    margin-bottom: 2rem;

                    .arrow {
                        transform: rotate(180deg);
                    }

                }

                .body {
                    display: block;
                }

            }

        }

    }

    .filtersBtn {
        margin-top: 3rem;
        position: sticky;
        bottom: 2rem;

        .mobileBtn {
            margin-top: 1rem;
            display: none;
        }

    }

}

.fixedBtn {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    z-index: 20;
    backdrop-filter: blur(var(--blur)) brightness(0.8);
    height: var(--field-large);
    width: var(--field-large);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 2.5rem;
    border: 2px solid var(--text);
    transition: 0.3s;
    display: none;

    &.hide {
        transform: translateX(-50%) translateY(200%);
    }

    &:hover {
        color: var(--contrast);
        background: var(--theme);
    }

    &[data-count]:not([data-count="0"]):after {
        content: attr(data-count);
        position: absolute;
        top: 0;
        right: 0;
        background: var(--theme);
        color: var(--contrast);
        line-height: 20px;
        font-size: 11px;
        font-weight: 600;
        min-width: 20px;
        padding: 0 3px;
        text-align: center;
        border-radius: 100%;
        transition: 0.2s;
    }

}

@media (max-width: 1200px) {

    .fixedBtn {
        display: flex;
    }

    .filters {
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
        top: calc(var(--mobile-header) + 2rem);
        z-index: 1000;
        background: var(--grey-bg);
        padding-bottom: 1rem;
        border-radius: var(--rad-large) var(--rad-large) 0 0;
        overflow: auto;
        transition: 0.4s;

        &:not(.show) {
            transform: translateY(100%);
        }

        .mobileHeader {
            display: flex;
        }

        .filtersBtn {

            .mobileBtn {
                display: block;
            }

        }

    }

}