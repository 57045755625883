.wrapper {
    display: flex;
    align-items: baseline;
    font-size: var(--fz-smaller);
    user-select: none;

    label {
        cursor: pointer;
    }

    .checkbox {
        flex-shrink: 0;
        display: flex;
        align-items: center;

        input {
            display: none;
        }

        label {
            width: var(--checkbox);
            height: var(--checkbox);
            border: 1px solid var(--field-border);
            border-radius: var(--rad-small);
            overflow: hidden;
            display: block;
            transition: 0.2s;
            cursor: pointer;
            background: var(--field-bg);

            &:before {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 100%;
                transform: translateY(-100%);
                transition: 0.3s;
                color: var(--bg);
                font-weight: 600;
            }

        }

        input:checked + label {
            background: var(--theme);
            border-color: transparent;

            &:before {
                transform: none;
            }

        }

        input:checked:active + label {
            background: var(--theme-dark);
        }

        &.error {
            
            label {
                border-color: var(--red);
                box-shadow: 0 0 0 1px var(--red), 0 0 0 5px var(--red-focus);
            }

            input:checked + label {
                background: var(--red);    
            }
    
            input:checked:active + label {
                background: var(--red-dark);
            }

        }

    }

    .color {
        width: var(--checkbox);
        height: var(--checkbox);
        margin-right: 0.3em;
        display: inline-block;
        margin-left: 1rem;
        border-radius: var(--rad);
        position: relative;

        &:after {
            content: '';
            position: absolute;
            border: 1px solid var(--border);
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: inherit;
        }

    }

    .label {
        margin-left: 1rem;
        flex-grow: 1;

        &.smallMargin {
            margin-left: 0.2rem;
        }

    }

}