.preview {
    margin-bottom: 2rem;
    width: 100%;

    .inner {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4rem 0;

        .billboard {
            width: 100%;
            height: 50vh;
            border-radius: var(--rad);

            img {
                width: 100%;
                height: 100%;
                object-fit: cover; // Добавляем для корректного отображения
                border-radius: var(--rad);
            }
        }

        .info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4rem 0;


        }
    }

    // Медиазапрос для экранов до 768px (мобильные устройства)
    @media (max-width: 768px) {
        .inner {
            gap: 2rem 0;

            .billboard {
                height: 30vh; // Уменьшаем высоту для мобильных
            }

            .info {
                gap: 2rem 0;

                h1 {
                    font-size: 2.5em;
                    margin: 2rem 0;
                }
            }
        }
    }

    // Медиазапрос для экранов до 480px (маленькие устройства)
    @media (max-width: 480px) {
        .inner {
            gap: 1.5rem 0;

            .billboard {
                height: 25vh; // Еще меньше для самых маленьких экранов

                img {
                    border-radius: var(--rad);
                }
            }

            .info {
                gap: 1.5rem 0;
            }
        }
    }
}