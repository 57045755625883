.section {
    padding: 5rem 0;
    overflow: hidden;

    .inner {

        .slider {
            position: relative;
            padding-bottom: calc(var(--field-smaller) + 4rem);
            margin-top: 4rem;

            .sliderWrapper {
                overflow: hidden;
            }

            .sliderControll {
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
                display: flex;
                align-items: center;

                .arrows {
                    display: flex;
                    align-items: center;

                    & > *:not(:last-child) {
                        margin-right: 1rem;
                    }

                }

                .scrollbar {
                    flex-grow: 1;
                    margin: 0 2rem;
        
                    &.scrollbarLine {
                        background: var(--grey-bg);
                        border-radius: 4px;
                        height: 4px;
                    }
                
                    .scrollbarDrag {
                        background: var(--border);
                        height: 4px;
                        border-radius: 4px;
                    }
                
                }

            }

        }

    }

}

@media (max-width: 768px) {

    .section {

        .inner {

            .slider {
                padding-bottom: 0;

                .sliderControll {
                    display: none;
                }

            }

        }

    } 

}