.section {
    margin-bottom: 4rem;

    .inner {
        display: flex;
        align-items: flex-start;

        .body {
            width: 80%;
            padding-right: 7rem;
        }

        .aside {
            width: 20%;
            position: sticky;
            top: 5rem;
        }

    }

}

@media (max-width: 768px) {

    .section {

        .inner {
            display: block;

            .body {
                padding-right: 0;
                width: 100%;
                padding-bottom: 5rem;
            }

            .aside {
                position: static;
                top: 0;
                width: 100%;
                background: var(--grey-bg-light);
                padding: 1rem 2rem;
                border-radius: var(--rad);
            }

        }

    }

}