.section {
    padding: 4rem 0;

    .inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 1;
            font-size: 6rem;
            border-radius: 100%;
            width: 8rem;
            height: 8rem;
            position: relative;
            z-index: 2;
            background: var(--theme-opacity);

            .iconInner {
                display: flex;
            }

            &:before,
            &:after {
                content: '';
                z-index: -1;
                position: absolute;
                border-radius: inherit;
                background: inherit;
            }

            &:before {
                top: -1rem;
                right: -1rem;
                bottom: -1rem;
                left: -1rem;
            }

            &:after {
                top: -2rem;
                right: -2rem;
                bottom: -2rem;
                left: -2rem;
            }

            &:not(:last-child) {
                margin-bottom: 3rem;
            }

        }

        .title {
            font-size: var(--h2);

            &:not(:last-child) {
                margin-bottom: 0.1em;
            }

        }

        .text {
            font-size: var(--fz-larger);
            font-weight: 500;
            color: var(--text-grey);

            &:not(:last-child) {
                margin-bottom: 0.8em;
            }

        }

    }

}

@media (max-width: 768px) {

    .section {

        .inner {

            .title {
                font-size: var(--h4);
            }

        }

    }

}