.cartBtn {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--text);
    line-height: 1;
    background: var(--grey-bg-opacity);
    backdrop-filter: blur(var(--blur));
    padding: 0 1.2rem;
    height: var(--field-smaller);
    border-radius: var(--rad);
    font-size: var(--fz-smaller);

    .icon {
        font-size: 1.8em;
        display: flex;
        margin-right: 0.2em;
        position: relative;

        &[data-count]:not([data-count="0"]):after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            background: var(--red);
            width: 8px;
            height: 8px;
            border-radius: 50%;
        }

    }

    .price {
        font-weight: 500;
    }

}