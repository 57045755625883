.wrapper {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
}
.showPasswordIcon {
    z-index: 100;
    position: absolute;
    right: 2rem;
    top: 1.5rem;
    color: var(--text-grey);
    
}
.showPasswordIcon:hover{
    opacity: 50%;
    transform: scale(1.2);
    cursor: pointer;
}
.label {
    font-size: var(--fz-large);
    margin-bottom: 0.5rem;
    display: block;
    color: var(--text-grey);

    .mark {
        color: var(--red);
        margin-left: 0.2em;
    }

}

.input {
    width: 100%;
    height: var(--field);
    font-size: var(--fz);
    border: 1px solid transparent;
    border-radius: var(--rad);
    padding: 0 1.5rem;
    outline: none;
    transition: 0.3s;
    background: none;
    color: var(--text);
    font-weight: 400;
    appearance: none;

    &.primary {
        border: 1px solid var(--field-border);
        background: var(--field-bg);

        &:hover {
            background: var(--grey-bg);
        }
    
        &:focus {
            border-color: var(--grey-bg-dark);
            box-shadow: 0 0 0 1px var(--grey-bg-dark);
            background: var(--grey-bg);
        }

    }

    &.blur {
        backdrop-filter: blur(var(--blur));
        background: var(--grey-bg-opacity);
    
        &:focus {
            border-color: var(--grey-bg-dark);
            box-shadow: 0 0 0 1px var(--grey-bg-dark);
        }

    }

    &::placeholder {
        color: var(--text-grey-light);
    }

    &.small {
        --fz: var(--fz-small);
        --field: var(--field-small);
    }

    &.smaller {
        --fz: var(--fz-smaller);
        --field: var(--field-smaller);
    }

    &.large {
        --fz: var(--fz-large);
        --field: var(--field-large);
    }

    &.larger {
        --fz: var(--fz-larger);
        --field: var(--field-larger);
    }

    &.error {
        border-color: var(--theme);
        --theme: var(--red);
        --theme-focus: var(--red-focus);
    }

}

.icon,
.afterIcon {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(var(--fz) * 1.2);
    padding: 0 1.5rem;
    pointer-events: none;
    color: var(--text-grey-light);
    z-index: 0;

    &.afterIcon {
        left: auto;
        right: 0;
    }
    
}

.icon + .input {
    padding-left: 4.2rem;
}

.afterIcon + .input {
    padding-right: 4.2rem;
}

.btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
}

.description {
    margin-top: 0.8rem;
    font-size: var(--fz-small);
    color: var(--text-grey);
    line-height: 1.3;

    &:before {
        margin-right: 0.3em;
        transform: translateY(10%);
        display: inline-block;
    }

}

.errorMessage {
    margin-top: 0.6rem;
    font-size: var(--fz-large);
    color: var(--red);
    line-height: 1.3;

    &:before {
        margin-right: 0.3em;
        transform: translateY(10%);
        display: inline-block;
    }

}

.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding-top: 4px;

    &:not(.show) {
        display: none;
    }

}