.mobileBtn {
    position: relative;
    --size: 2rem;
    width: var(--size);
    height: var(--size);

    span {
        position: absolute;
        left: 0;
        right: 0;
        height: 2px;
        background: var(--text-grey-light);
        transition: 0.2s;

        &:first-child {
            top: 25%;
        }

        &:last-child {
            top: 75%;
        }

    }

    &.show {

        span {
            background: var(--text);

            &:first-child {
                top: 50%;
                transform: rotate(45deg);
            }

            &:last-child {
                top: 50%;
                transform: rotate(-45deg);
            }

        }    

    }

}