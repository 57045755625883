.list {

    & > *:not(:last-child) {
        margin-bottom: 2rem;
    }

}

@media (max-width: 768px) {

    .list {

        & > *:not(:last-child) {
            margin-bottom: 4rem;
            padding-bottom: 4rem;
            border-bottom: 1px solid var(--border);
        }

    }

}