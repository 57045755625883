.additional {
  margin: 4rem 0;
 
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  .textWrapper {
    position: relative;
    max-height: 200px;
    overflow: hidden;
    transition: max-height 0.3s ease;

    &.expanded {
      max-height: 3000px; /* Достаточно большое значение, чтобы вместить всё содержимое */
    }

    .text {
      margin: 0;
      padding: 1rem;
      color: var(--text-grey);
 
     

      p {
        font-size: 16px; // Размер основного текста
        line-height: 1.6; // Межстрочный интервал
        margin-bottom: 1rem; // Отступ снизу для абзацев
      }
      ul {
        padding-left: 1rem;
        margin-bottom: 1rem;
      }
      h1 {
        font-size: 2rem; // Размер для заголовка h1
        line-height: 1.2; // Межстрочный интервал для заголовка
        margin-bottom: 1.5rem; // Отступ снизу для заголовка
      }

      h2 {
        font-size: 1.8rem; // Размер для заголовка h2
        line-height: 1.3; // Межстрочный интервал для заголовка
        margin-bottom: 1.3rem; // Отступ снизу для заголовка
      }

      h3 {
        font-size: 1.5rem; // Размер для заголовка h3
        line-height: 1.4; // Межстрочный интервал для заголовка
        margin-bottom: 1.1rem; // Отступ снизу для заголовка
      }

      h4 {
        font-size: 1.2rem; // Размер для заголовка h4
        line-height: 1.5; // Межстрочный интервал для заголовка
        margin-bottom: 1rem; // Отступ снизу для заголовка
      }

      h5 {
        font-size: 1rem; // Размер для заголовка h5
        line-height: 1.6; // Межстрочный интервал для заголовка
        margin-bottom: 0.9rem; // Отступ снизу для заголовка
      }

      h6 {
        font-size: 0.8rem; // Размер для заголовка h6
        line-height: 1.7; // Межстрочный интервал для заголовка
        margin-bottom: 0.8rem; // Отступ снизу для заголовка
      }
    }

    .fadeOut {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100px;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgb(25, 26, 27)
      );
    }
  }

  .readMore {
    cursor: pointer;
    padding: 1rem;
    color: #fff;
    text-decoration: underline;
  }

  .readMore:hover {
    color: #ccc;
  }
}
