.order {
    border-radius: var(--rad);
    background: var(--grey-bg);
}


.info {
    padding: 3rem;

    .title {
        font-weight: 600;
        font-size: var(--h5);
        margin-bottom: 0.5rem;

        span {
            color: var(--text-grey);
        }

    }

    .comment {
        margin-bottom: 1rem;
        font-weight: 500;
        color: var(--text-grey);
        font-size: var(--fz-larger);
    }

    .meta {
        display: flex;
        margin-bottom: 1.5rem;

        .line {
            width: calc(100% + 0.6rem);
            margin: -0.3rem;
            display: flex;
            flex-wrap: wrap;

            .metaItem {
                margin: 0.3rem;
                display: flex;
                align-items: center;
                font-weight: 500;
                font-size: var(--fz-smaller);
                background: var(--grey-bg-dark);
                padding: 0.5rem 1rem;
                border-radius: var(--rad);

                .icon {
                    color: var(--theme);
                    margin-right: 0.4rem;
                    display: flex;
                    font-size: 1.1em;
                    color: var(--text-grey);
                }
                
            }

        }

    }

    .btns {
        display: flex;

        & > *:not(:last-child) {
            margin-right: 1rem;
        }

    }

}

.productsList {
    margin-bottom: 2.5rem;
    padding-top: 1rem;
}

@media (max-width: 768px) {

    .order {
        background: none;
        border-radius: 0;

        .info {
            padding: 0;
        }

    }

}