.header {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    z-index: 32;
    margin-bottom: 2rem;

    .topHeader {

        .inner {
            display: flex;
            align-items: center;
            height: var(--top-header);

           .social {
                display: flex;
                align-items: center;
                
                margin-right: 3rem;

                & > *{
                    color: var(--text);
                    font-size: var(--h4);
                    padding: 0.7rem;
                    border-radius: 0.5rem;
                }
            }

            a {
                color: var(--text);
                font-size: var(--fz-small);
                text-decoration: none;
            }

            nav {
                margin-right: auto;

                ul {
                    display: flex;
                    list-style: none;

                    li:not(:last-child) {
                        margin-right: 3rem;
                    }

                }

            }

            .contacts {
                display: flex;
                align-items: center;

                .contactsItem {
                    display: flex;
                    align-items: center;

                    &:before {
                        font-size: 1.2em;
                        margin-right: 0.3em;
                    }

                    &:not(:last-child) {
                        margin-right: 3rem;
                    }

                }

            }

            .social {
                display: flex;
                align-items: center;

                a {
                    font-size: var(--fz-larger);
                    display: flex;
                    align-items: center;

                    &:not(:last-child) {
                        margin-right: 1rem;
                    }

                }

            }

        }

    }

    .middleHeader {

        .inner {
            display: flex;
            align-items: center;
            height: var(--middle-header);

            .logoWrapper {
                margin-right: 2rem;
            }

            .searchWrapper {
                width: 0;
                flex-grow: 1;
            }

            .rightBlock {
                margin-left: 2rem;
            }

        }

    }

    .bottomHeader {

        .inner {
            display: flex;
            align-items: center;
            height: var(--bottom-header);
            padding-bottom: 1rem;

            nav {
                margin-right: auto;

                ul {
                    display: flex;
                    list-style: none;
                    cursor: pointer;

                    li {

                        a {
                            font-weight: 500;
                            text-decoration: none;
                            padding: 1rem 1.2rem;
                            border-radius: var(--rad);
                            transition: 0.2s;
                            display: block;
                            line-height: 1;
                            cursor: pointer;

                            &:hover,
                            &.activeLink {
                                background: var(--grey-bg-dark);
                            }

                        }

                        &:not(:last-child) {
                            margin-right: 2rem;
                        }

                    }

                }

            }

        }

    }

    .mobileHeader {
        display: none;

         .wrapper {
            padding-bottom: 5rem;
        }

        .inner {
            height: var(--mobile-header);
            display: flex;
            align-items: center;
            justify-content: space-between;

            .leftBlock,
            .rightBlock {
                display: flex;
                align-items: center;

                &>*:not(:last-child) {
                    margin-right: 3rem;
                }

            }

        }

    }

}

.fixedBtns {
    position: fixed;
    z-index: 34;
    left: 50%;
    transform: translateX(-50%);
    top: 3rem;
    backdrop-filter: blur(var(--blur));
    box-shadow: var(--shadow);
    display: flex;
    align-items: center;
    border-radius: 3rem;
    padding: 6px;
    background: var(--grey-bg-opacity);
    transition: 0.3s;
    pointer-events: none;
    transform: translateX(-50%) translateY(-100%);
    opacity: 0;

    &.show {
        pointer-events: all;
        transform: translateX(-50%);
        opacity: 1;
    }

    .link {
        text-decoration: none;
        height: var(--field-smaller);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3rem;
        font-weight: 500;
        position: relative;

        &[data-count]:not([data-count="0"]):after {
            content: attr(data-count);
            position: absolute;
            top: 0;
            right: 0;
            background: var(--theme);
            color: var(--contrast);
            transform: translate(20%, -10%);
            line-height: 20px;
            font-size: 11px;
            font-weight: 600;
            min-width: 20px;
            padding: 0 3px;
            text-align: center;
            border-radius: 100%;
            transition: 0.2s;
        }

        &.icon {
            width: var(--field-smaller);
            font-size: 1.4em;
            margin-right: 0.5rem;
        }

        &:not(.icon) {
            padding: 0 1rem;
        }

        &.bg {
            background: var(--border);
        }

    }

}

@media (max-width: 768px) {

    .header {
        position: sticky;
        transition: 0.3s;
        margin-bottom: 1rem;
        background: linear-gradient(var(--bg), rgba(0, 0, 0, 0));

        .topHeader {
            display: none;
        }

        .middleHeader {
            display: none;
        }
        
        .bottomHeader {
            display: none;
        }

        .mobileHeader {
            display: block;
        }

    }

    .fixedBtns {
        display: none;
    }

}

@media (min-width: 768px) and (max-width: 1200px) {

    .header {
        position: sticky;
        transition: 0.3s;
        margin-bottom: 1rem;
        background: linear-gradient(var(--bg), rgba(0, 0, 0, 0));

        .topHeader {
            display: none;
        }

        .middleHeader {
            display: none;
        }

        .bottomHeader {
            display: none;
        }

        .mobileHeader {
            display: block;
        }

    }

    .fixedBtns {
        display: none;
    }

}