@import url('./variables.scss');
@import url('./fonts.scss');
@import url('./icons.scss');

*,
*:before,
*:after {
    box-sizing: border-box;
}

*::selection {
    border: 1px solid var(--border);
    background: #fff;
    color: transparent !important;
    -webkit-text-stroke-color: #000;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
}

img {
    max-width: 100%;
    max-height: 100%;
}

hr {
    width: 100%;
    height: 1px;
    background: var(--border);
    border: none;
}

a {
    color: var(--theme);
    cursor: pointer;
}

svg {
    cursor: pointer;
}

html {
    font-size: var(--rem);
    scroll-behavior: smooth;
    overflow-y: scroll;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

* {
    cursor: auto;
}

body {
    margin: 0;
    padding: 0;
    background: var(--bg);
    -webkit-tap-highlight-color: transparent;
    min-height: 100vh;

    .swiper {
        overflow: visible;

 
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-track {
        background: var(--border);
        // border-radius: 3px;
    }

    ::-webkit-scrollbar-thumb {
        background: var(--text);
        // border-radius: 3px;
    }

    .rc-slider {
        height: 4px;
        background: var(--grey-bg);
        border-radius: 3px;
        position: relative;

        .rc-slider-track {
            position: absolute;
            top: 0;
            bottom: 0;
            background: var(--border);
        }

        .rc-slider-handle {
            height: 16px;
            width: 16px;
            position: absolute;
            top: -6px;
            background: var(--grey-bg-dark);
            border: 2px solid var(--border);
            border-radius: 100%;
        }

    }

}

body,
input,
textarea,
button,
select {
    font-size: var(--fz);
    font-family: var(--font);
    color: var(--text);
    line-height: var(--line-height);
    font-weight: 300;
    cursor: pointer;
}

@mixin margin($normal, $small, $large) {

    &>*:not(:last-child):not(.no-margin) {
        --margin: #{$normal};
        margin-bottom: var(--margin);

        &.small-margin {
            --margin: #{$small};
        }

        &.large-margin {
            --margin: #{$large};
        }

    }

}

.margin {
    @include margin(3rem, 2rem, 4rem);

    &__smaller {
        @include margin(2rem, 1rem, 3rem);
    }

    &__small {
        @include margin(1rem, 0.5rem, 1.5rem);
    }

    &__larger {
        @include margin(5rem, 4rem, 6rem);
    }

    &__large {
        @include margin(7rem, 6rem, 8rem);
    }

}

.styledLink {
    color: var(--theme);
    text-decoration: underline;
    position: relative;
    z-index: 1;
    border-radius: var(--rad-small);
    cursor: pointer;
    transition: 0.2s;
    padding: 3px;
    margin: -3px;

    &:hover {
        color: var(--theme-dark);
    }

    &:active,
    &:focus {
        background: var(--theme-opacity);
        box-shadow: 0 0 0 4px var(--theme-focus);
    }

}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
    line-height: 1.3;
    font-weight: 600;
}

h5,
.h5,
h6,
.h6 {
    line-height: 1.3;
    font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
blockquote,
ol {
    padding: 0;
    margin: 0;
}

.subtitle {
    font-weight: 500;
}

@mixin title($index) {

    h#{$index},
    .h#{$index} {
        --title-size: var(--h#{$index});
        font-size: var(--title-size);

        &.smaller {
            --title-size: calc(var(--h#{$index}) * 0.9);
        }

        &.small {
            --title-size: calc(var(--h#{$index}) * 0.8);
        }

        &.larger {
            --title-size: calc(var(--h#{$index}) * 1.1);
        }

        &.large {
            --title-size: calc(var(--h#{$index}) * 1.2);
        }

    }
}

@for $i from 1 through 6 {
    @include title($i);
}

@mixin sectionPadding {
    &:not(.bottom):not(.top) {
        padding: var(--padding) 0;
    }

    &.top {
        padding-top: var(--padding);
    }

    &.bottom {
        padding-bottom: var(--padding);
    }
}

.sectionPadding {
    --padding: calc(var(--field) * 1.5);
    @include sectionPadding;

    &__smaller {
        --padding: calc(var(--field-smaller) * 1.5);
        @include sectionPadding;
    }

    &__small {
        --padding: calc(var(--field-small) * 1.5);
        @include sectionPadding;
    }

    &__larger {
        --padding: calc(var(--field-larger) * 1.5);
        @include sectionPadding;
    }

    &__large {
        --padding: calc(var(--field-large) * 1.5);
        @include sectionPadding;
    }

}

@media (max-width: 768px) {}

@media (min-width: 768px) and (max-width: 1200px) {}

@media (min-width: 1200px) and (max-width: 1300px) {}