.list {
    .inner {
        .icon {
            color: var(--text-grey);
            font-size: large;
            align-self: center;
            margin-left: 1rem;

            &:hover {
                transform: scale(1.1);
                transition: all 0.3s;
            }
        }

        &>*:not(:last-child) {
            margin-bottom: 1rem;
        }

        .info {
            display: none;
            align-items: center;
            flex-direction: column;
            transition: 0.3s ease-in-out;
            transform: translateY(-30px);
        }

        .open {
            margin: 1rem 0;
            background-color: var(--blackboard);
            border-radius: 1rem;
            border: 1px dashed var(--border);
            padding: 2rem 2rem;
            display: block;
            gap: 2rem;
            transition: 0.3s ease-in-out;
            transform: translate(0);
            max-height: 26rem;
            overflow-y: auto;

            & h6 {
                margin-bottom: 1rem;
                font-size: medium;
            }

            & div {
                opacity: 70%;

                ul {
                    text-decoration: none;
                    list-style-type: circle;
                    margin-top: 2rem;
                }
            }
        }

        .item {
            line-height: 1.3;
            display: flex;
            align-items: baseline;

            .title {
                color: var(--text-grey);
            }

            .line {
                margin: 0 1rem;
                flex-grow: 1;
                border-bottom: 1px dashed var(--border);
            }

            &.large {
                font-size: var(--fz-larger);
                font-weight: 600;

                .title {
                    color: var(--text);
                }
            }

            .functions {
                .functionText {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }
 &>* {
     margin-bottom: 0.9rem;
 }
                .functionItem {
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    .functionText{
                        display: flex;
                        align-items: center;
                    }
                    .info {
                        margin-top: 1rem;
                        display: none;
                        align-items: center;
                        flex-direction: column;
                        transition: 0.3s ease-in-out;
                        transform: translateY(-30px);
                    }

                    .open {
                        margin: 1rem 0;
                        background-color: var(--blackboard);
                        border-radius: 1rem;
                        border: 1px dashed var(--border);
                        padding: 2rem 2rem;
                        display: block;
                        gap: 2rem;
                        transition: 0.3s ease-in-out;
                        transform: translate(0);
                        max-height: 26rem;
                        overflow-y: auto;

                        & h6 {
                            margin-bottom: 1rem;
                            font-size: medium;
                        }

                        & div {
                            opacity: 70%;

                            ul {
                                text-decoration: none;
                                list-style-type: circle;
                                margin-top: 2rem;
                            }
                        }
                    }
                }
            }
        }
    }

    &.two {
        .inner {
            margin-bottom: -0.9rem;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            &>* {
                margin-bottom: 0.9rem;
            }

            .item {
                width: calc(50% - 2rem);
            }
        }
    }

    .info.open .item {
        background: #000; // Change this if needed
    }
}