.section {
    --grid: 4;
    padding: 3rem 0;

    .inner {

        .grid {
            display: grid;
            grid-gap: 3rem;
            grid-template-columns: repeat(var(--grid), 1fr);

            .item {
                background: var(--grey-bg);
                padding: 2rem;
                border-radius: var(--rad);

                .itemContent {
                    display: flex;
                    align-items: center;
                }

                .icon {
                    line-height: 1;
                    display: flex;
                    font-size: 4rem;
                    margin-right: 1.5rem;
                }

                .text {
                    font-weight: 600;
                    line-height: 1.3;
                }

            }

        }

    }

}

@media (max-width: 768px) {

    .section {
        --grid: 2;

        .inner {

            .grid {
                grid-gap: 1rem;

                .item {

                    .itemContent {
                        flex-direction: column;
                        text-align: center;

                        .icon {
                            margin-right: 0;
                            margin-bottom: 1rem;
                            background: var(--theme-opacity);
                            border-radius: 50%;
                            width: 6rem;
                            height: 6rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 3rem;
                        }

                        .text {
                            font-weight: 400;
                        }

                    }

                }

            }

        }

    }

}

@media (min-width: 768px) and (max-width: 1200px) {

    .section {
        --grid: 2;
    }

}