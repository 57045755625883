.breadcrumbs {
    line-height: 1.2;
    font-size: var(--fz-smaller);
    display: flex;
    align-items: center;
}

.link {
    color: var(--text-grey);
}

.icon {
    font-size: var(--fz-larger);
}

.sep {
    color: var(--text-grey);
    display: flex;
    padding: 0 1rem;
}

a.link {
    text-decoration: none;
    transition: 0.2s;

    &:hover {
        color: var(--theme);
    }

}