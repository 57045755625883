.spinner {
    --size: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.padding {
    padding: var(--size) 0;
}

@keyframes spinner {
    from {
        transform: none;
    }

    to {
        transform: rotate(360deg);
    }
}

.inner {
    padding: calc(var(--size) * 0.5) 0;
    width: var(--size);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 100%;

        path,
        rect {
            fill: var(--theme);
        }
    }
}

.large {
    --size: var(--field-large);
}

.larger {
    --size: var(--field-larger);
}

.primary {
    --size: var(--field);
}

.smaller {
    --size: var(--field-smaller);
}

.small {
    --size: var(--field-small);
}

/* Add browser prefixes for keyframes */
@-webkit-keyframes spinner {
    from {
        -webkit-transform: none;
        transform: none;
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* Add browser prefixes for .inner padding and width */
.inner {
    padding: -webkit-calc(var(--size) * 0.5) 0;
    width: var(--size);
}

/* Add browser prefixes for .inner svg width and path/rect fill */
.inner svg {
    width: 100%;

    path,
    rect {
        fill: var(--theme);
    }
}