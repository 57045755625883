.section {
    margin-bottom: 4rem;

    .inner {
        display: flex;
        justify-content: space-between;
        position: relative;
        padding: var(--padding);
        background: var(--grey-bg);
        border-radius: var(--rad);
        --padding: 2px;
        --inside-rad: calc(var(--rad) - 2px);

        .map {
            width: calc(75% - 2px);
            background: var(--bg);
            border-radius: var(--inside-rad);
            overflow: hidden;
        }

        .info {
            width: 25%;
            position: relative;
            z-index: 2;

            .item {
                padding: 2rem 2.5rem;
                background: var(--bg);
                border-radius: var(--inside-rad);

                .title {
                    font-size: var(--fz-smaller);
                    color: var(--text-grey);
                    margin-bottom: 0.4rem;
                }

                .text {
                    text-decoration: none;
                    display: block;
                    font-size: var(--fz-larger);
                    white-space: pre-line;

                    &:not(:last-child) {
                        margin-bottom: 0.4rem;
                    }

                }

                .links {
                    display: flex;

                    .line {
                        display: flex;
                        flex-wrap: wrap;
                        margin: -0.3rem -0.6rem;

                        .link {
                            text-decoration: none;
                            border-bottom: 2px solid var(--border);
                            margin: 0.3rem 0.6rem;
                            display: block;
                            line-height: 1.2;
                        }

                    }

                }

                &:not(:last-child) {
                    margin-bottom: 2px;
                }

            }

        }

    }

}

@media (max-width: 768px) {

    .section {

        .inner {
            display: block;

            .map {
                margin-bottom: 2px;
                width: 100%;
                height: 30rem;
            }

            .info {
                width: 100%;
            }

        }

    }

}

@media (min-width: 768px) and (max-width: 1200px) {

    .section {

        .inner {
            display: block;

            .map {
                margin-bottom: 2px;
                width: 100%;
                height: 40rem;
            }

            .info {
                width: 100%;
                display: grid;
                grid-gap: 2px;
                grid-template-columns: 1fr 1fr;

                .item {

                    &:not(:last-child) {
                        margin-bottom: 0;
                    }

                }

            }

        }

    }

}