.form {
    position: relative;
    z-index: 12;

    .results {
        position: absolute;
        left: 0;
        top: calc(100% + 4px);
        right: 0;
        background: var(--grey-bg-opacity);
        backdrop-filter: blur(var(--blur));
        border-radius: var(--rad);
        padding: 0.3rem;
        box-shadow: var(--shadow);
        min-width: 100%;

        &:not(.show) {
            display: none;
        }

        .item {
            padding: 1rem;
            display: flex;
            align-items: center;
            text-decoration: none;
            border-radius: var(--rad);
            transition: 0.2s;
            color: var(--text);

            &:hover {
                background: var(--border);
            }

            .image {
                flex-shrink: 0;
                margin-right: 1.5rem;
                width: 3rem;
                height: 5rem;
                display: flex;
                align-items: center;
                justify-content: center;

                .noImage {
                    display: flex;
                    font-size: 3rem;
                    color: var(--text-grey-light);
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

            }

            .text {
                width: 0;
                flex-grow: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                line-height: 1;

                .title {
                    font-weight: 500;
                    font-size: var(--fz-larger);
                    margin-bottom: 0.4em;
                }

                .meta {
                    color: var(--text-grey);
                    font-size: var(--fz-smaller);
                    display: flex;
                    align-items: baseline;

                    & > *:not(:last-child) {
                        margin-right: 1rem;
                    }

                }

            }

        }

    }

}