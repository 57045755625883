.user {
    border-bottom: 1px solid var(--border);
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;

    .name {
        font-size: var(--h6);
        font-weight: 500;
    }

    .email {
        color: var(--text-grey);
        font-weight: 400;
    }

}

nav {

    ul {
        width: calc(100% + 2rem);
        list-style: none;
        margin: 0 -1rem;

        li {

            .item {
                display: flex;
                align-items: center;
                text-decoration: none;
                color: var(--text);
                padding: 1rem;
                line-height: 1.3;
                border-radius: var(--rad);
                font-weight: 400;
                transition: 0.2s;

                .icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 3rem;
                    height: 3rem;
                    background: var(--border);
                    border-radius: 3rem;
                    margin-right: 1rem;
                    font-size: 1.2em;
                }

                &.activeLink,
                &:hover {
                    background: var(--theme-opacity);
                }
                
            }

        }

    }

}