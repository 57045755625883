.section {
    padding-bottom: 5rem;

    .inner {
        display: flex;
        align-items: flex-start;

        .body {
            width: 70%;
            padding-right: 7rem;

            .item {

                .title {
                    font-weight: 600;
                    line-height: 1.3;
                    font-size: var(--h4);
                    margin-bottom: 2.5rem;

                    span {
                        color: var(--text-grey);
                    }

                }

                &:not(:last-child) {
                    margin-bottom: 7rem;
                }

            }

        }

        .aside {
            width: 30%;
            position: sticky;
            top: 5rem;
        }

    }

}

@media (max-width: 768px) {

    .section {

        .inner {
            flex-direction: column;

            .aside {
                position: relative;
                top: 0;
                width: 100%;
                margin-bottom: 5rem;
                order: -1;
            }

            .body {
                width: 100%;
                padding-right: 0;
            }

        }

    }

}

@media (min-width: 768px) and (max-width: 1200px) {

    .section {

        .inner {
            flex-direction: column;

            .aside {
                position: relative;
                top: 0;
                width: 100%;
                margin-bottom: 5rem;
                order: -1;
            }

            .body {
                width: 100%;
                padding-right: 0;
            }

        }

    }

}