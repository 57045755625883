.btn {
    cursor: pointer;
    padding: 0;
    line-height: 1;
    background: none;
    font-weight: 400;
    transition: 0.3s, transform 0s;
    overflow: hidden;
    position: relative;
    border-radius: var(--rad);
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    user-select: none;
    outline: none;
    width: fit-content;
    color: var(--text);
    text-decoration: none;
    letter-spacing: 0.05em;
    user-select: none;
    font-size: var(--fz);
   

    &.rounded {
        border-radius: 4rem;
        cursor: pointer;
    }

    

    span {

        height: calc(var(--field) - 2px);
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 2;
        transition: transform 0.3s;
        cursor: pointer;
    }

    span.text {
        cursor: pointer;
        padding: 0 calc(var(--field) * 0.5);

        .beforeIcon,
        .afterIcon {
                cursor: pointer;
            font-size: 1.1em;
            
            &:before {
                    cursor: pointer;
                font-weight: 500;
            }

        }

        .beforeIcon {
            cursor: pointer;
            margin-right: 0.3em;
        }

        .afterIcon {
            cursor: pointer;
            margin-left: 0.3em;
        }

    }

    span.icon {
        cursor: pointer;
        width: var(--field);
        font-size: 1.6em;
    }

    &:active {
cursor: pointer;
        span {
            transform: scale(0.9);
        }

    }

    &:hover {
cursor: pointer;
        &:before {
            height: 100%;
        }

    }

    &.primary {
        cursor: pointer;
        background: var(--theme);
        color: var(--contrast);

        &:hover {
            background: var(--theme-light);
        }

    }

    &.grey {
        cursor: pointer;
        background: var(--grey-bg-dark);

        &:hover {
            color: var(--contrast);
            background: var(--theme-light);
        }

    }

    &.blur {
        cursor: pointer;
        backdrop-filter: blur(var(--blur)) brightness(0.7);
    }

    &.outline {
        cursor: pointer;
        border-color: var(--border);

        &:hover {
            border-color: transparent;
            color: var(--contrast);
            background: var(--theme-light);
        }

    }

    &:disabled {
        cursor: pointer;
        background: var(--grey-bg-light) !important;
        pointer-events: none;

        .text,
        .icon {
            color: var(--text-grey-light);
        }

    }

    &.small {
        cursor: pointer;
        --fz: var(--fz-small);
        --field: var(--field-small);

        span.icon {
            cursor: pointer;
            font-size: 1em;
        }

    }

    &.smaller {
        --fz: var(--fz-smaller);
        --field: var(--field-smaller);
    }

    &.large {
        --fz: var(--fz-large);
        --field: var(--field-large);
    }

    &.larger {
        --fz: var(--fz-larger);
        --field: var(--field-larger);
    }

    &.full {
        width: 100%;
        justify-content: center;
    }

    @keyframes spinner {
        from {
            transform: none;
        }

        to {
            transform: rotate(360deg);
        }
    }

    .spinner {
        cursor: pointer;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;

        &:before {
            content: '';
            animation: spinner 0.5s infinite linear;
            display: block;
            border-radius: 100%;
            width: 1.2em;
            height: 1.2em;
            border: 2px solid var(--text);
            border-right: 2px solid transparent;
        }

    }

    &.loading {

        .text,
        .icon {
            opacity: 0;
        }

    }

}