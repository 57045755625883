.section {
    padding: 3rem 0 7rem 0;

    .priceBlock {
        line-height: 1;

        .discount {
            border-radius: 1rem;
            padding: 1rem 1rem;
            line-height: 1;
            font-weight: 400;
            color: var(--text-grey);
            color: white;
            font-weight: bold;
            background: linear-gradient(135deg, #ff7e5f, #feb47b);
        }

        .installment {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            justify-content: flex-start;
            margin-top: 2rem;
            border-radius: var(--rad);
            padding: 1rem 1rem;
            line-height: 1;
            font-weight: 400;
            color: white;
            background: linear-gradient(to right, #FC96FC, #959EF1, #0DC9D4);
            width: fit-content;
            cursor: pointer;

            .mainTitle {
                font-weight: bold;
                cursor: inherit;
            }

            .furtherTitle {
                font-weight: normal;
                text-decoration: underline;
                cursor: inherit;
            }
        }


        .title {
            margin-bottom: 1rem;
        }

        .price {
            font-size: var(--h4);
            font-weight: 600;
            margin-bottom: 2rem;
        }

        .oldPrice {
            font-size: var(--h6);
            font-weight: 600;
            text-decoration: line-through;
            opacity: 70%;
        }

        .fields {
            display: flex;
            flex-direction: column;
            gap: 2rem;

            .quantity {
                width: 20rem;
                flex-grow: 1;
            }

            &>*:not(:last-child) {
                margin-right: 1rem;
            }

        }

    }

    .inner {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;

        .image {
            width: 32%;
            display: flex;
            justify-content: center;
            position: sticky;
            top: 5rem;

            .imageWrapper {
                position: relative;
                display: flex;

                .blurImage {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background-position: center;
                    background-size: cover;
                    filter: blur(6rem);
                    opacity: 0.6;
                    transform: scale(1.2);
                    transition: 0.7s;
                }

                img {
                    position: relative;
                    z-index: 2;
                    max-height: 50rem;
                }

            }

            .noImage {
                padding: 2rem 0;
                font-size: 12rem;
                color: var(--text-grey-light);
            }

        }

        .main {
            width: 32%;

            .subtitle {
                font-size: var(--fz-smaller);
                font-weight: 500;
            }

            &>*:not(:last-child) {
                margin-bottom: 3rem;
                padding-bottom: 3rem;
                border-bottom: 1px solid var(--border);
            }

            .header {

                .title {
                    margin-bottom: 1.5rem;
                    font-size: var(--h5);

                    &:not(:last-child) {
                        margin-top: 1rem;
                    }

                }

                .meta {
                    display: flex;

                    .line {
                        display: flex;
                        flex-wrap: wrap;
                        width: calc(100% + 2rem);
                        margin: -0.5rem -1rem;


                        .item {
                            margin: 0.5rem 1rem;
                            line-height: 1;
                            font-size: var(--fz-smaller);
                            color: var(--text-grey);

                            .text {
                                margin-right: 0.3em;
                            }

                            a {
                                color: var(--text-grey);
                            }

                        }

                    }

                }

            }

            .mainPriceBlock {
                display: none;


            }

            .info {

                .title {
                    margin-bottom: 2rem;
                }

                .item {
                    display: flex;
                    flex-direction: column;
                    font-size: var(--fz-smaller);

                    .subtitle {
                        margin-bottom: 1rem;
                    }

                    &:not(:last-child) {
                        margin-bottom: 3rem;
                    }

                }

            }

        }

        .aside {
            width: 24%;
            position: sticky;
            top: 5rem;

            .sidebarPriceBlock {
                background: var(--grey-bg);
                padding: 2.5rem;
                border-radius: var(--rad);


            }

            .row {

                &:not(:last-child) {
                    border-bottom: 1px solid var(--border);
                }

            }

            .title {
                color: var(--text-grey);
            }

            .infoItems {
                margin-top: 2rem;

                .item {
                    display: flex;
                    align-items: center;
                    color: var(--text-grey);
                    text-decoration: none;

                    &:not(:last-child) {
                        margin-bottom: 0.4rem;
                    }

                    .icon {
                        font-size: 1.2em;
                        margin-right: 0.6rem;
                    }

                }

                a.item {

                    .text {
                        text-decoration: underline;
                    }

                }

            }

        }

    }

}

@media (max-width: 768px) {

    .section {
        padding-bottom: 4rem;

        .inner {
            display: block;

            .image {
                width: 100%;
                margin-bottom: 5rem;
                position: static;

                .blurImage {
                    display: none;
                }

            }

            .main {
                width: 100%;

                .mainPriceBlock {
                    display: block;
                }

            }

            .aside {
                display: none;
            }

        }

    }

}

@media (min-width: 768px) and (max-width: 1200px) {

    .section {

        .inner {

            .image {
                width: 35%;
            }

            .main {
                width: 55%;

                .mainPriceBlock {
                    display: block;
                }

            }

            .aside {
                display: none;
            }

        }

    }

}