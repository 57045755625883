.about {
    margin: 2rem 0;
    padding: 8rem 0;
    background: var(--grey-bg);
    position: relative;
    overflow: hidden;

    .image {
        position: absolute;
        width: 60%;
        right: 0;
        top: -5%;
        bottom: -5%;
        pointer-events: none;

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }

    }

    .content {
        width: 40%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        z-index: 2;

        .title {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        p {
            color: var(--text-grey);
        }

        .btns {
            display: flex;
            align-items: center;
            justify-content: center;

            & > * {
                margin: 0 0.5rem;
            }

        }

    }

}

@media (max-width: 768px) {

    .about {

        .image {
            width: 90%;
            opacity: 0.2;
            mix-blend-mode: darken;
        }

        .content {
            width: 100%;
        }

    }

}

@media (min-width: 768px) and (max-width: 1200px) {

    .about {

        .image {
            width: 90%;
            opacity: 0.2;
            mix-blend-mode: darken;
        }

        .content {
            width: 100%;
        }

    }

}